// ————————————————————————————————————————— 
// VARIABLES: for partials
// ————————————————————————————————————————— 
// for layout
$column-count: 12;
$column-count-m: 8;
$column-count-s: 4;
$grid-width: 1920;
$column-margin: 30;

// ————————————————————————————————————————— 
// IMPORTS 
// ————————————————————————————————————————— 
// these are partials
// init for mob theme
@import "init";
// grid and base layout classes
@import "layout";

@font-face {
    font-family: 'Soehne';
    src: url('../fonts/soehne-buch.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Soehne';
    src: url('../fonts/soehne-buch-kursiv.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Soehne Bold';
    src: url('../fonts/soehne-kraftig.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Tiempos';
    src: url('../fonts/tiempos-headline-light.woff2') format('woff2');
    font-display: swap;
    font-style: normal;
}


// ————————————————————————————————————————— 
// VARIABLES: for base
// ————————————————————————————————————————— 
$headerheight: 80px;
$fullbrowserheight: calc(100vh - 80px);

// color
$black: #231F20;
$ccmwhite: #F3F1F2;
$grey: #E7E4E5;
$pink: #C6168D;
$magenta: #A3238E;
$purple: #812990;
$indigo: #5C2D91;
$blue: #2E3192;

//fonts 
$sohneRegular: 'Soehne', sans-serif;
$sohneBold: 'Soehne Bold', sans-serif;
$tiempos: 'Tiempos', serif;

// easings
$easing: cubic-bezier(0.83, 0, 0.17, 1);


// ————————————————————————————————————————— 
// M I X I N S
// ————————————————————————————————————————— 
// @include background-image("image.jpg");
@mixin background-image($url) {
    background: $grey url($url) no-repeat center;
    background-size: cover;
}

// @include background-fill();
@mixin background-fill() {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}


// ————————————————————————————————————————— 
// S T A T E S
// ————————————————————————————————————————— 
::selection {
    background: rgba(46, 49, 146, 0.2);
}

::-moz-selection {
    background: rgba(46, 49, 146, 0.2);
}

.keyboard-focus :focus {
    outline: 3px solid red;
    outline-offset: 3px;
}

// skip link defaults
#skipLink {
    padding: 20px 10px 11px 10px;
    display: inline-block;
    position: absolute;
    z-index: 123456789;
    background-color: white;
    top: -200px;
    left: 20px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.5);
    transition: .3s all $easing;

    &:focus {
        top: 0;
    }
}

// removed Feb 2022
// #circle {
//     position: fixed;
//     height: 80px;
//     width: 80px;
//     border-radius: 50%;  
//     background-color: rgba(255, 255, 255, 0.4);
//     z-index: 123456789;
//     pointer-events: none;
//     mix-blend-mode: overlay;
// }

// @media (prefers-reduced-motion) { 
//     #circle {
//         display: none;
//     }
// }

// smoothscroll scrollbar - always show
.scrollbar-track {
	opacity: 1 !important;
  z-index: 9999999999;
}

// ————————————————————————————————————————— 
// A11Y links in new window styling
// https://www.w3.org/WAI/WCAG21/Techniques/general/G201.html
// ————————————————————————————————————————— 
a[target=_blank] {
    position: relative;

    span {
        position: absolute;
        left: -9000px;
        width: 0;
        overflow: hidden;
    }

    &:hover span,
    &:focus span,
    &:active span {
        display: block;
        position: absolute;
        top: 1em;
        left: 1em;
        width: 12em;
        border: 1px solid $blue;
        background-color: $grey;
        color: $blue;
        text-align: center
    }
}

// ————————————————————————————————————————— 
// core styles & classes
// ————————————————————————————————————————— 
html {
  overflow-x: hidden;
}

body,
input,
textarea,
radio,
checkbox,
.strip {
    background-color: transparent;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-align: left;
}

body {
    background-color: $ccmwhite;
    overflow: hidden;
}

.pv {
    margin-top: 100px;
    margin-bottom: 100px;
}

.pv-small {
    margin-top: 50px;
    margin-bottom: 50px;
}

.pv-med {
    margin-top: 80px;
    margin-bottom: 80px;
}

.pv-lg {
    margin-top: 200px;
    margin-bottom: 200px;
}

video,
embed,
iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
}

/* Fixes potential theme css conflict. */
.acf-map {
    width: 100%;
    height: 100vh;

    img {
        max-width: inherit !important;
    }
}

li p,
p .button {
    font-size: 100%;
}


// ————————————————————————————————————————— 
// ST Typography
// —————————————————————————————————————————
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

p,
li,
a,
small,
address,
.p {
    margin: 0;
    padding: 0;
    font-size: 170%;
    font-family: $sohneRegular;
    line-height: 160%;
    transform: rotate(-0.1deg);
    direction: ltr;
}

small {
  font-size: 160%;
}

p,
.p {
    padding-top: 20px;
}

dd,
p.lg-p,
p.p-lg,
.p-lg p {
    font-size: 200%;
    font-family: $sohneRegular;
    line-height: 170%;
    transform: rotate(-0.1deg);
}

li a,
p a {
    font-size: 100%;
    color: $blue;
    // transition: .2s all $easing;
    text-decoration-thickness: 1px;

    &:hover {
        color: $magenta;
        text-decoration-thickness: 2px;
    }
}

b,
strong {
    font-family: $sohneBold;
}

footer a,
a {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
}

p.small {
    font-size: 140%;
}

// ————————————————————————————— headings

.h1 {
    font-family: $tiempos;
    font-size: clamp(470%, 6vw, 1100%);
    line-height: 96%;
    color: $purple;
    transform: rotate(-0.1deg);
}

.general-content h2,
.h2 {
    font-family: $tiempos;
    font-size: 510%;
    line-height: 120%;
    color: $blue;
    margin-top: 40px;
    margin-bottom: 10px;
    transform: rotate(-0.1deg);
}

.general-content h3,
.h3 {
    font-family: $sohneBold;
    font-size: 300%;
    margin-bottom: 10px;
    margin-top: 40px;
    transform: rotate(-0.1deg);
    line-height: 120%;
}

.general-content h4,
.h4 {
    font-family: $sohneBold;
    font-size: 220%;
    line-height: 130%;
    margin-top: 25px;
    margin-bottom: 5px;
    transform: rotate(-0.1deg);
}

dt,
.general-content h5,
.general-content h6,
.h5,
.h6 {
    font-family: $sohneBold;
    font-size: 200%;
    line-height: 150%;
    transform: rotate(-0.1deg);
}

// ————————————————————————————— lists

div div div {
    ul {
        list-style-type: disc;
    }

    ul ul {
        margin-top: 10px;
        margin-bottom: 20px;
        list-style-type: circle;
    }

    ul ul ul {
        list-style-type: square;
    }

    ul,
    ol {
        padding: 8px 35px;

        li {
            margin: 5px 0;
        }
    }

    ol {
        list-style-type: decimal;
    }

    ol ol {
        margin-top: 10px;
        margin-bottom: 20px;
        list-style-type: lower-alpha;
    }

    ol ol ol {
        list-style-type: lower-roman;
    }

    ol ol,
    ul ol {
        list-style-type: lower-roman;
    }

    li li {
        font-size: 100%;
    }
}

iframe {
    width: 100%;
}

// ————————————————————————————————————————— 
// buttons
// —————————————————————————————————————————

.button {
    display: inline-block;
    font-family: $sohneBold;
    color: white;
    font-size: 170%;
    background-color: $purple;
    padding: 12px 20px 14px;
    text-decoration: none !important;
    line-height: 1;
    position: relative;
    border: none;
    transition: .25s all $easing;
    overflow: hidden;

    &:hover {
        background-color: $blue; 

        span {
          position: absolute;
          display: block;
    
          &:nth-child(1) {
            top: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background: $pink;
            animation: animate1 2s linear infinite;
          }
          @keyframes animate1 {
            0% {
              left: -100%;
            }
            50%,
            100% {
              left: 100%;
            }
          }
    
          &:nth-child(2) {
            top: -100%;
            right: 0;
            width: 3px;
            height: 100%;
            background: $pink;
            animation: animate2 2s linear infinite;
            animation-delay: 0.5s;
          }
    
          @keyframes animate2 {
            0% {
              top: -100%;
            }
            50%,
            100% {
              top: 100%;
            }
          }
          &:nth-child(3) {
            bottom: 0;
            right: 0;
            width: 100%;
    
            background: $pink;
            animation: animate3 2s linear infinite;
            animation-delay: 1s;
          }
          @keyframes animate3 {
            0% {
              right: -100%;
              height: 3px;
            }
            50%,
            100% {
              height: 2px;
              right: 100%;
            }
          }
    
          &:nth-child(4) {
            bottom: -100%;
            left: 0;
            width: 3px;
            height: 100%;
            background: $pink;
            animation: animate4 2s linear infinite;
            animation-delay: 1.5s;
          }
          @keyframes animate4 {
            0% {
              bottom: -100%;
            }
            50%,
            100% {
              bottom: 100%;
            }
          }
        }
    }
}

.link {
    font-family: $sohneBold;
    color: $blue;
    font-size: 170%;
    line-height: 1;
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
    text-underline-offset: 4px;
    transition: .2s all $easing;
    background: transparent;
    border: none;

    &:hover {
        color: $pink;
        text-decoration-color: $magenta;
    }
}

.video-wrapper {
    position: relative;

    .video-control {
        height: 44px;
        width: 44px;
        position: absolute;
        border-radius: 100px;
        border: none;
        background-color: white;
        bottom: 30px;
        transition: all .4s cubic-bezier(0.65, 0, 0.35, 1);
        left: 30px;
        background-image: url('/../assets/_images/play-icon.png');
        cursor: pointer;
        z-index: 1;
    
        &.pause {
            background-image: url('/../assets/_images/pause-icon.png');
        }
    
        &:hover {
            transform: scale(1.05);
        }
    }
}

// ————————————————————————————————————————— 
// layers & sections
// —————————————————————————————————————————
.home-hero {
    margin-top: 0 !important;

    p,
    h1 {
        margin-left: 54px;
    }

    h1 {
        margin-bottom: 30px;

        span:nth-of-type(1) {
            color: $pink;
        }
    
        span:nth-of-type(2) {
            color: $magenta;
        }
    
        span:nth-of-type(4) {
            color: $blue;
        }
    }
}

.section-heading {
    h2 {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            background-color: #2E3192;
            height: 10px;
            width: 10px;
            left: -3vw;
            top: -1vw;
        }

        &:after {
            content: '';
            position: absolute;
            border-top: 2px solid $magenta;
            border-left: 2px solid $magenta;
            height: 80px;
            width: 80px;
            left: calc(-3vw - 70px);
            top: calc(-1vw - 70px);
        }
    }
}

.general-content {
    h2 {
        margin-top: 60px;
        margin-bottom: 0;
    }
    img {
          max-width: 300px;
          height: auto;
    }
}

.stats {
    >div {
        position: relative;
        margin-left: 5vw;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr;
        grid-template-rows: 1fr 1fr;
        gap: 0px 0px;
        grid-template-areas:
            "icon num"
            "blurb blurb";

        img {
            grid-area: icon;
        }

        h3 {
            grid-area: num;
            align-self: center;
            margin-left: 10px;
        }

        p {
            grid-area: blurb;
            color: $blue;
            margin-top: 0;
        }

        &:before {
            content: '';
            position: absolute;
            background-color: #2E3192;
            height: 10px;
            width: 10px;
            left: -5vw;
        }
    }
}

.property {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 0px 0px;
    grid-template-areas:
        "image image"
        "name link"
        "cat cat";
    margin-bottom: 30px;

    &.ft-properties__property--ft {
        img {
            height: clamp(300px, 90vh, 1000px);
        }
    }

    &:not(.ft-properties__property--ft) {
        img {
            height: clamp(300px, 30vw, 600px);
        }
    }

    .video-wrapper,
    img {
        grid-area: image;
        width: 100%;
        object-fit: cover;
    }

    h3 {
        grid-area: name;
    }

    h4 {
        grid-area: cat;
    }

    .p {
        padding-top: 0;
    }

    .link {
        grid-area: link;
        margin-top: 31px;
        justify-self: end;
    }
}

.cta {
    img {
        height: clamp(300px, 75vh, 700px);
        width: 100%;
        object-fit: cover;
    }

    .cta__content {
        background-color: $grey;
        padding: 60px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        gap: 0px 0px;
        grid-template-areas:
            "h but"
            "blurb blurb";
        margin-top: clamp(-280px, -65vh, -520px);
        position: relative;

        &:before {
            content: '';
            position: absolute;
            border-top: 10px solid $blue;
            border-left: 10px solid $blue;
            height: 80px;
            width: 80px;
            top: -10px;
            left: -10px;
        }

        &:after {
            content: '';
            position: absolute;
            border-top: 10px solid $blue;
            border-right: 10px solid $blue;
            height: 80px;
            width: 80px;
            top: -10px;
            right: -10px;
        }

        h2 {
            color: $black;
            grid-area: h;
            align-self: center;
            margin: 0;
        }

        .button {
            grid-area: but;
            align-self: center;
            justify-self: end;
        }

        p {
            grid-area: blurb;
            margin-top: 10px;
        }
    }
}

.ft-partners {
    .ft-partners__partner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
        gap: 0px 0px;
        grid-template-areas:
            "image image"
            "logo link"
            "blurb blurb";

        .ft-partners__partner__ft-image {
            grid-area: image;
            margin-bottom: 30px;
            height: clamp(300px, 75vh, 700px);
            width: 100%;
            object-fit: cover;
        }

        .ft-partners__partner__logo {
            grid-area: logo;
        }

        p {
            grid-area: blurb;
            margin-top: 20px;
        }

        .link {
            grid-area: link;
            align-self: start;
            justify-self: end;
        }
    }
}

.partners {
    align-items: center;
  
    .partners__logos {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        img {
            height: clamp(80px,10vw,150px);
            width: clamp(140px,14vw,200px);
            object-fit: contain;
            margin: 0 30px;
        }
    }
}

.team {
    .team__container {
        display: flex;
        flex-wrap: wrap;
    }

    .team__container__team-member {
        width: calc(33% - 30px);
        margin-right: 30px;
        margin-bottom: 60px;
        
        h3 span {
          font-size: 77%;
          font-family: "Soehne",sans-serif;
        }

        img {
            height: clamp(350px, 30vw, 600px);
            object-fit: cover;
            width: 100%;
        }

        .p {
            padding-top: 0;
        }

        .link {
            float: right;
            margin-top: 20px;
        }

        .team__container__team-member__more-bio {
            clear: both;
            display: none;

            &.visible {
                display: block;
            }
        }
    }
}

.property-section {
    h2.h3,
    .lg-p {
        padding: 0;
        margin: 0;
    }
}

.full-w-map,
.full-w-image {
    p {
        padding: 0;
    }
}

.full-w-image {
    img {
        width: 100%;
    }
}

.property-intro {
    position: relative;

    >img {
        height: clamp(300px, 75vh, 700px);
        width: 100%;
        object-fit: cover;
    }

    .property-intro__content {
        align-self: center;
        background-color: $grey;
        width: 460px;
        padding: 60px;
        position: absolute;
        //right: -20px; caused horizontal scroll 
        right: 0px;
        height: clamp(450px, 90vh, 800px);

        &:before {
            content: '';
            display: block;
            position: absolute;
            background: $blue;
            height: clamp(250px, 60vh, 600px);
            width: 10px;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
        }
        
        img {
          width: clamp(150px, 12vw, 230px);
          height: auto;
          object-fit: contain;
        }

        h2.h3 {
            margin-bottom: 25px;
        }

        dt,
        dd {
            width: calc(50% - 10px);
            display: inline-block;
            margin: 8px 0;
            padding: 0;
            line-height: 1.2;
        }

        dt {
            margin-right: 17px;
        }
    }
}

.image-sidebar {
  img {
    height: 30vw;
    width: 100%;
    object-fit: cover;
  }
}

.full-w-map {
    #map {
        width: 100% !important;
        height: clamp(300px, 70vh, 1000px) !important;
    }
}

.contact-map {
    #map {
        width: 100% !important;
        height: clamp(300px, 70vh, 500px) !important;
    }
}

// ————————————————————————————————————————— 
// history page
// —————————————————————————————————————————

.history {
    position: relative;
    overflow: hidden;

    .history-line {
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: $pink;
        z-index: -1;
        left: calc(50% - 1px);
        top: 160px;
    }

    .history-entry {
        &.history-entry--right {
            direction: rtl;
        }

        .history-entry__image img {
            height: clamp(300px, 55vh, 500px);
            width: 100%;
            object-fit: cover;
        }

        .history-entry__content {
            position: relative;
            background-color: $grey;
            padding: 70px 40px;

            h2 {
                color: $black;
            }

            &:before {
                content: '';
                position: absolute;
                border-top: 10px solid $blue;
                border-left: 10px solid $blue;
                height: 80px;
                width: 80px;
                top: -10px;
                left: -10px;
            }

            &:after {
                content: '';
                position: absolute;
                border-bottom: 10px solid $blue;
                border-right: 10px solid $blue;
                height: 80px;
                width: 80px;
                bottom: -10px;
                right: -10px;
            }

        }
    }
}


// ————————————————————————————————————————— 
// footer
// —————————————————————————————————————————

.main-footer {
    background-color: $blue;
    padding-bottom: 80px;
    padding-top: 44px;

    * {
        color: white;
        font-family: $sohneRegular;
    }

    .main-footer__small {
        align-self: end;
    }

    .h5 {
        padding: 0;
    }

    .main-footer__nav a {
        transition: .2s all $easing;

        &:hover {
            opacity: 0.5;
        }
    }

    span {
        display: block;
        height: 10px;
        position: absolute;
        width: 100vw;
        bottom: 0;

        &.main-footer__stripe-4 {
            background-color: $indigo;
            margin-bottom: 30px;
        }

        &.main-footer__stripe-3 {
            background-color: $purple;
            margin-bottom: 20px;
        }

        &.main-footer__stripe-2 {
            background-color: $magenta;
            margin-bottom: 10px;
        }

        &.main-footer__stripe-1 {
            background-color: $pink;
        }
    }


}


// —————————————————————————————————————————
// IMPORTS
// —————————————————————————————————————————
// these are partials, must go in the _sass folder
@import "responsive.scss";