/* Desktop Nav */
@media only screen and (min-width: 800px) {
	.main-header {
		overflow: visible;

		.main-header__nav {
			> ul {
				display: flex;
				justify-content: flex-end;

				> li {
					padding: 3px 24px 20px 24px;
					position: relative;
					font-size: 100%;
					line-height: 1;
					z-index: 123456789;
					perspective: 1000px;

					a {
						font-family: $sohneBold;
						font-size: 180%;
						color: $blue;
						line-height: 1.2;
						transition: all 0.15s $easing;

						&.active,
						&:hover {
							color: $pink;
						}
					}

					> ul {
						z-index: 1234;
						width: 215px;
						margin-top: 0;
						//
						position: absolute;
						top: 100%;
						left: 0;
						perspective: 1000px;

						// &:before {
						// 	content: "";
						// 	border-top: 2px solid $blue;
						// 	border-left: 2px solid $blue;
						// 	width: 80px;
						// 	height: 80px;
						// 	display: block;
						// 	position: absolute;
						// 	top: -2px;
						// 	left: -2px;
						// }

						// submenu animation & style
						// https://codepen.io/codypearce/pen/PdBXpj
						@for $num from 1 through 8 {
							.sub-item-#{$num} {
								transform-origin: top center;
								animation: slideDown 300ms ($num * 60ms) ease-in-out forwards;
							}
						}

						@keyframes slideDown {
							0% { 
							  opacity: 0;
							  transform: translateY(-60px);
							}
						  
							100% {
							  opacity: 1;
							  transform: translateY(0);
							}
						  }
						// end submenu animation & style

						li {
							padding: 16px;
							display: none;
							opacity: 0;
							background-color: white;

							a {
								position: relative;
								transform: none !important;

								&:after {
									content: "";
									display: block;
									opacity: 0;
									position: absolute;
									background-color: $blue;
									height: 8px;
									width: 8px;
									right: -16px;
									top: 9px;
									transition: all 0.2s $easing;
									perspective: 1000px;
								}

								&:hover:after {
									opacity: 1;
								}
							}
						}
					}

					&:hover > ul li,
					&:focus-within > ul li {
						display: block;
					}

					&.main-header__nav__has-children:after {
						content: "+";
						font-family: $sohneBold;
						font-size: 180%;
						color: $blue;
						line-height: 1;
					}
				}
			}
		}

		#navToggle {
			display: none;
		}
	}
}

/* Mobile Nav */
@media only screen and (max-width: 799px) {
	#navToggle {
		border: none;
		position: absolute;
		top: 16px;
		right: 10px;
		z-index: 123;
		height: 44px;
		width: 44px;

		&[aria-expanded="true"] {
			.nav-toggle__close {
				display: block;
			}

			.nav-toggle__open {
				display: none;
			}
		}

		.nav-toggle__close {
			display: none;
		}
	}

	.main-header__nav {
		li {
			font-size: 100%;

			.main-header__nav__link {
				font-family: $sohneBold;
				font-size: 180%;
				line-height: 1;
				color: $blue;
			}
		}

		> ul {
			position: absolute;
			background: $ccmwhite;
			height: 100vh;
			width: 100vw;
			left: 0;
			top: 0;
			margin: 0;
			padding: 130px 20px 0 20px;
			z-index: 12;
			display: none;

			&.is-active {
				display: block;
			}

			> li {
				width: 100%;
				margin: 30px 0;
			}
		}

		.main-header__nav__has-children {
			display: grid;
			grid-template-columns: 1fr 1.7fr;
			grid-template-rows: 1fr;

			ul {
				display: flex;
				flex-direction: column;
				align-items: start;
				width: 60%;

				li a {
					display: inline-block;
					font-family: $sohneRegular;
					font-size: 160%;
					line-height: 1.2;
					margin-bottom: 10px;
					color: $blue;
				}
			}
		}
	}
}

@media only screen and (min-width: 700px) {
	.history {
		margin-bottom: 130px;

		.history-entry {
			&.history-entry--left .history-entry__image {
				margin-top: 80px;
			}

			&.history-entry--right .history-entry__content {
				margin-top: 80px;
			}
		}
	}
}

@media only screen and (min-width: 1200px) and (max-width: 1500px) {
	body {
		font-size: 55%;
	}
	
	.p, a, address, li, p, small {
	  font-size: 190%;
	}
}

@media only screen and (min-width: 1200px) {
	.team .team__container__team-member:nth-child(3n) {
		margin-right: 0;
	}
}

@media only screen and (min-width: 700px) and (max-width: 1200px) {
	body {
		font-size: 53%;
	}

	.pv {
		margin-top: 60px;
		margin-bottom: 60px;
	}

	.pv-small {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.pv-med {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.pv-lg {
		margin-top: 120px;
		margin-bottom: 120px;
	}

	.home-hero p,
	.home-hero h1 {
		margin-left: 0;
		margin-bottom: 20px;
	}

	.main-header .main-header__nav > ul > li {
		padding: 3px 18px 20px 18px;
	}

	// —————————————————————————————————————————
	// typography
	// —————————————————————————————————————————

	.general-content h2,
	.h2 {
		font-size: 390%;
		line-height: 130%;
	}

	.general-content h3,
	.h3 {
		font-size: 250%;
		line-height: 120%;
		margin-bottom: 20px;
	}

	// —————————————————————————————————————————
	// layers & sections
	// —————————————————————————————————————————

	.team .team__container__team-member {
		width: calc(50% - 20px);
		margin-right: 20px;
		margin-bottom: 60px;

		img {
      height: 400px;
      width: 310px;
		}
	}

	.property-intro .property-intro__content {
		width: 400px;
		padding: 40px;
	}

	.partners .partners__logos {
		margin-top: 30px;
	}

	.history {
		.history-entry.history-entry--left .history-entry__image {
			margin-right: -60px;
		}

		.history-entry.history-entry--right .history-entry__image {
			margin-left: -60px;
		}
	}

	.full-w-map p, .full-w-image p {
		margin-top: 10px;
	}
}

@media only screen and (max-width: 700px) {
	// —————————————————————————————————————————
	// core & header/footer
	// —————————————————————————————————————————
	.pv-lg,
	.pv {
		margin-top: 30px;
		margin-bottom: 30px;
	}

	.pv-med,
	.pv-small {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.page-header > p,
	.home-hero h1,
	.partners .partners__logos {
		margin-top: 20px !important;
	}

	.main-header__logo-area {
		position: relative;
		z-index: 123;

		img {
			width: 110px;
		}
	}

	.main-footer {
		.main-footer__nav,
		.main-footer__site-title,
		.main-footer__small {
			margin-top: 40px;
		}

		.main-footer__nav {
			order: 6;
		}

		.main-footer__spacer {
			display: none;
		}

		.main-footer__small {
			order: 7;
		}
	}

	.video-wrapper {
		position: relative;
		overflow: hidden;
		padding-bottom: 100%;
		height: 0;

		iframe,
		video,
		embed {
			position: absolute;
			top: 0;
			left: -50%;
			width: auto;
			height: 100%;
		}
	}

	.video-control {
		bottom: 10px;
		left: 10px;
	}

	// —————————————————————————————————————————
	// typography
	// —————————————————————————————————————————
	p,
	li,
	a,
	small,
	address,
	.p,
	dd,
	p.lg-p,
	p.p-lg,
	.p-lg p {
		margin: 0;
		padding: 0;
		font-size: 160%;
		font-family: $sohneRegular;
		line-height: 150%;
	}

	small {
		font-size: 150%;
	}

	p,
	.p {
		padding-top: 12px;
	}

	b,
	strong {
		font-family: $sohneBold;
	}

	// ————————————————————————————— headings

	.general-content h2,
	.h2 {
		font-size: 370%;
		line-height: 130%;
		margin-top: 20px;
		margin-bottom: 25px;
	}

	.general-content h3,
	.h3 {
		font-size: 230%;
		margin-bottom: 20px;
		margin-top: 20px;
		line-height: 120%;
	}

	.general-content h4,
	.h4 {
		font-size: 170%;
		line-height: 120%;
		margin-bottom: 5px;
		margin-top: 15px;
	}

	dt,
	.general-content h5,
	.general-content h6,
	.h5,
	.h6 {
		font-family: $sohneBold;
		font-size: 160%;
		line-height: 150%;
	}

	// —————————————————————————————————————————
	// buttons
	// —————————————————————————————————————————

	.button {
		font-size: 160%;
		padding: 8px 15px 10px;
	}

	.link {
		font-size: 160%;
	}

	// —————————————————————————————————————————
	// layers & sections
	// —————————————————————————————————————————
	.home-hero {
		p,
		h1 {
			margin-left: 0;
		}

		// .h1 {
		//     font-size: 480%;
		// }
	}

	.stats > div {
		margin-left: 8vw;
		margin-top: 30px;

		&:before {
			height: 6px;
			width: 6px;
			left: -8vw;
		}

		img {
			max-width: 60px;
			max-height: 60px;
			object-fit: contain;
		}

		h3 {
			font-size: 550%;
			align-self: end;
		}
	}

	.property {
		margin-bottom: 60px;

		.link {
			margin-top: 16px;
		}
	}

	.section-heading {
		margin-top: 40px;

		h2:before {
			height: 6px;
			width: 6px;
			left: 34px;
			top: -26px;
		}

		h2:after {
			height: 40px;
			width: 40px;
			left: 0;
			top: -60px;
		}
	}

	.cta {
		img {
			width: calc(100% - 40px);
			margin: auto;
			height: 300px;
		}

		.cta__content {
			display: block;
			padding: 30px;
			margin-top: -40px;

			.button {
				margin-top: 30px;
			}
		}
	}

	.partners .partners__logos img {
		margin: 0 10px;
	}

	.history .history-entry .history-entry__content {
		padding: 40px 30px;

		&:before {
			top: -8px;
			left: -8px;
		}

		&:after {
			bottom: -8px;
			right: -8px;
		}

		.h2 {
			margin-bottom: 5px;
		}
	}

	.cta .cta__content,
	.history .history-entry .history-entry__content {
		&:before,
		&:after {
			height: 50px;
			width: 50px;
			border-width: 8px;
		}
	}

	.cta .cta__content {
		&:before {
			top: -8px;
			left: -8px;
		}

		&:after {
			top: -8px;
			right: -8px;
		}
	}

	.full-w-map #map {
		height: 300px !important;
	}

	.team .team__container__team-member {
		width: 100%;
		margin-right: 0;
		margin-bottom: 50px;

		img {
			height: auto;
		}
	}

	.property-intro {
		img {
			height: clamp(300px, 30vw, 600px);
		}

		.property-intro__content {
			padding: 40px;
			position: relative;
			margin-top: -40px;
			width: 100%;
			height: auto;

			&:before {
				height: 70%;
			}

			img {
				height: 100px;
			}
		}
	}

	.ft-partners .ft-partners__partner p {
		margin-top: 0;
		margin-bottom: 50px;
	}
	
	.image-sidebar {
    img {
      height: auto;
    }
  }


	.full-w-map p, .full-w-image p {
		margin-top: 10px;
	}

	.contact-map {
		margin-top: 30px;
	}
}
