/*
# MOB 2020 Grid System
# last modified October 19, 2020
*/

.wo,
.wo-stacked,
.wo-full {
    max-width: $grid-width + px;
    position: relative;
    text-align: center;
    margin-left: auto !important;
    margin-right: auto !important;
    
    * {
        text-align: left;
    }
    &.between {
        align-content: space-around;
    }
    .left {
        justify-self: start;
    }
    .right {
        justify-self: end;
    }
    .bottom {
        align-self: end; 
    }
    .center {
        justify-self: center;
        text-align: center;
        * {
            text-align: center;
        }
    }
}

.wo,
.wo-full {
    display: grid;
    grid-template-columns: repeat($column-count, 1fr);
    gap: $column-margin + px $column-margin + px;
    justify-items: stretch;
    align-items: start;
}

.wo,
.wo-stacked,
.wo-single {
    padding: 20px;
}

.wo-full {
    padding: 20px;
    max-width: 100% !important;
}

img[class^="col-"],
img[class*="col-"] {
    place-self: start;
}

@media only screen and (min-width: 1200px) {
    .l-hide {
        display: none !important;
    }

    // 1920 
    @for $a from 1 through ($column-count) {
        @for $b from 1 through ($column-count + 1) {
            .col-#{$a}-to-#{$b} {
                grid-column-start: $a;
                grid-column-end: $b;
            }
        }
    }
}

@media only screen and (min-width: 700px) and (max-width: 1200px) {
    // medium
    .wo,
    .wo-full {
        display: grid;
        grid-template-columns: repeat($column-count-m, 1fr);
        gap: 20px $column-margin + px;
        justify-items: stretch;
        align-items: start;
    }

    .m-hide {
        display: none !important;
    }

    @for $a-m from 1 through ($column-count-m) {
        @for $b-m from 1 through ($column-count-m + 1) {
            .m-col-#{$a-m}-to-#{$b-m} {
                grid-column-start: $a-m;
                grid-column-end: $b-m;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    // small (mobile)
    .wo,
    .wo-full {
        display: grid;
        grid-template-columns: repeat($column-count-s, 1fr);
        gap: 10px $column-margin + px;
        justify-items: stretch;
        align-items: start;
    }

    .s-hide {
        display: none !important;
    }

    .wo .right, .wo-stacked .right, .wo-full .right {
        justify-self: start;
    }

    @for $a-s from 1 through ($column-count-s) {
        @for $b-s from 1 through ($column-count-s + 1) {
            .s-col-#{$a-s}-to-#{$b-s} {
                grid-column-start: $a-s;
                grid-column-end: $b-s;
            }
        }
    }
}